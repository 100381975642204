import { ReactElement } from 'react';
import { Spinner, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { OverviewButton } from '@bq/components/OverviewButton/OverviewButton';
import { useDOMInputJSON } from '@bq/components/use-dom-input';

import { caseFilterTypes } from '../../Cases/Filters';
import { offerFilterTypes } from '../../Offers/List/filters';
import { useOverviews } from './api';
import { SalesOverviews as ISalesOverviews } from './types';

export const SalesOverviews = (): ReactElement => {
  // Definitely move this request to modal so it's not loaded until needed I guess
  const { data: overviews } = useOverviews();
  if (!overviews) {
    return <Spinner />;
  }

  return <OverviewsButtons overviews={overviews} />;
};

interface OverviewsButtonProps {
  overviews: ISalesOverviews;
}

const OverviewsButtons = ({
  overviews,
}: OverviewsButtonProps): ReactElement => {
  const { t } = useTranslation('Sales');
  const [stored, setStored] = useDOMInputJSON<ISalesOverviews>(
    '#settings-form',
    'sales[overviews]',
    overviews
  );

  return (
    <VStack w="full" align="start" mb={4}>
      <VStack w="full" align="start" mb={4}>
        <OverviewButton
          queryKey="Sales.Offers"
          filterTypes={offerFilterTypes}
          onChange={(value) => {
            setStored({ ...stored, offers: value });
          }}
          value={stored.offers}
        >
          {t('Sales:edit_offer_overviews')}
        </OverviewButton>
      </VStack>

      <OverviewButton
        queryKey="Sales.Cases"
        filterTypes={caseFilterTypes}
        onChange={(value) => {
          setStored({ ...stored, sales: value });
        }}
        value={stored.sales}
      >
        {t('Sales:edit_sales_overviews')}
      </OverviewButton>
    </VStack>
  );
};
