import { ReactElement, useCallback, useMemo, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ColorInput } from '@bq/components/ColorInput';
import { IOverviewEditorItem } from '@bq/components/Overviews';
import { Select, SelectOption } from '@bq/components/SelectWrappers';
import { LoadingModal } from '@bq/components/UseModal';
import { usePhonebookOverviews } from 'app/Modules/Phonebook/Assets/components/overviews';

import { ContactItem } from '../../HopsOperator/HopsContacts/types';
import { useGeneateCards } from './generate-cards';
import {
  OverviewOption,
  OverviewSelect,
  overviewToOption,
} from './OverviewSelect';

interface Props {
  setCards?: (cards: ContactItem[]) => void;
  defaultColor?: string | null;
}

export const CardGenerator = (props: Props): ReactElement => {
  const { data: overviews } = usePhonebookOverviews();

  if (!overviews) {
    return <LoadingModal />;
  }

  return <CardGeneratorContent {...props} overviews={overviews.people} />;
};

interface ContentProps extends Props {
  overviews: IOverviewEditorItem[];
}

const CardGeneratorContent = ({
  overviews,
  setCards,
  defaultColor = null,
}: ContentProps): ReactElement => {
  const { t } = useTranslation('Telephony');
  const suffixModes = useSuffixModeOptions();
  const overviewOptions = useMemo(() => {
    return overviews.map(overviewToOption);
  }, [overviews]);

  const [overview, setOverview] = useState<OverviewOption | null>(null);
  const [color, setColor] = useState<string | null>(defaultColor);
  const [suffixMode, setSuffixMode] = useState<SelectOption<string> | null>(
    suffixModes[0]
  );

  const generateCards = useGeneateCards();
  const generate = useCallback(async () => {
    const cards = await generateCards({
      color,
      suffixMode: suffixMode?.value ?? 'multiple',
      overview: overview?.overview ?? null,
    });

    setCards?.(cards);
  }, [color, setCards, generateCards, overview?.overview, suffixMode?.value]);

  return (
    <ModalContent>
      <ModalHeader>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <VStack>
          <HStack w="full">
            <FormControl>
              <FormLabel fontWeight="bold">
                {t('global:overviews.overview')}:
              </FormLabel>
              <OverviewSelect
                options={overviewOptions}
                value={overview}
                onChange={setOverview}
              />
            </FormControl>
          </HStack>
          <HStack w="full">
            <FormControl>
              <FormLabel fontWeight="bold">
                {t('Telephony:operator.suffix')}:
              </FormLabel>
              <Select
                value={suffixMode}
                onChange={setSuffixMode}
                isClearable={false}
                options={suffixModes}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="bold">{t('global:color')}:</FormLabel>
              <ColorInput value={color} onChange={setColor} />
            </FormControl>
          </HStack>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="green" onClick={generate}>
          {t('Telephony:operator.generate_contacts')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

function useSuffixModeOptions(): SelectOption<string>[] {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: 'multiple',
        label: t('Telephony:operator.suffix_mode_multiple'),
      },
      { value: 'never', label: t('Telephony:operator.suffix_mode_never') },
      { value: 'always', label: t('Telephony:operator.suffix_mode_always') },
    ],
    [t]
  );
}
