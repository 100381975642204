import { ReactElement } from 'react';

import { NumberInputProps } from '../types';
import { NumberInputSingle } from './NumberInputSingle';
import { NumberRangeInput } from './NumberRangeInput';

export const FilterNumberInput = ({
  value = 0,
  onChange,
  operator,
}: NumberInputProps): ReactElement => {
  if (operator === 'between') {
    const valueRange = !(typeof value === 'number') ? value : null;

    return <NumberRangeInput value={valueRange} onChange={onChange} />;
  }

  const valueNumber = typeof value === 'number' ? value : undefined;

  return (
    <NumberInputSingle
      value={valueNumber}
      onChange={(value) => {
        if (onChange) {
          onChange(value);
        }
      }}
    />
  );
};
