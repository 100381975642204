import { ReactElement } from 'react';
import { Flex, IconButton, useColorModeValue } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';

import { NumberInputProps } from '../types';
import { NumberTagInputSingle } from './NumberTagInputSingle';
import { NumberTagRangeInput } from './NumberTagRangeInput';

export const FilterNumberTag = ({
  value = 0,
  onChange,
  onRemove,
  isNew,
  operator,
}: NumberInputProps): ReactElement => {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');

  if (operator === 'between') {
    const assertValue = !(typeof value === 'number') ? value : null;

    return (
      <Flex background={bgColor} height="8" borderRightRadius="md">
        <NumberTagRangeInput
          value={assertValue}
          onChange={onChange}
          isNew={isNew}
        />
        <IconButton
          onClick={onRemove}
          variant="link"
          aria-label="Close"
          icon={<FaTimes />}
          size="xs"
        />
      </Flex>
    );
  }
  const assertValue = typeof value === 'number' ? value : undefined;

  return (
    <Flex background={bgColor} height="8" borderRightRadius="md">
      <NumberTagInputSingle
        value={assertValue}
        onChange={onChange}
        isNew={isNew}
      />
      <IconButton
        onClick={onRemove}
        variant="link"
        aria-label="Close"
        icon={<FaTimes />}
        size="xs"
      />
    </Flex>
  );
};
