import { useMemo, useState } from 'react';

import { useGetDateLocale } from '@bq/components/use-get-date-locale';
import { useCalendarFilters } from 'app/Modules/Events/Assets/components/Calendar/use-calendar-filters';

import { CalendarView, DaysOfWeek } from '../types';
import { useCurrentCalendarView } from '../use-current-calendar-view';
import { useFirstDayOfWeek } from '../use-first-day-of-week';
import { useWeekStartShift } from '../use-week-start-shift';
import {
  generateDayRange,
  generateMonthRange,
  generateWeekRange,
} from './utils';

interface Props {
  startDate?: Date;
  firstDayOfWeek?: DaysOfWeek;
  startingView?: CalendarView;
  weekShift?: number;
}

export const useCalendarController = ({
  startDate = new Date(),
  startingView = 'month',
}: Props) => {
  const view = useCurrentCalendarView(startingView);
  const firstDayOfWeek = useFirstDayOfWeek();
  const currentDate = useState<Date>(startDate);
  const locale = useGetDateLocale();
  const weekStartShift = useWeekStartShift();
  const [shift] = weekStartShift;
  const dateRange = useMemo(() => {
    switch (view[0]) {
      case 'day':
        return generateDayRange(currentDate[0]);
      case 'month':
        return generateMonthRange(currentDate[0], firstDayOfWeek[0], locale);
      case 'week':
        return generateWeekRange(
          currentDate[0],
          firstDayOfWeek[0],
          locale,
          shift
        );
      default:
        throw Error('Unknown view');
    }
  }, [currentDate, firstDayOfWeek, locale, shift, view]);
  const calendarFilters = useCalendarFilters();

  return useMemo(
    () => ({
      view,
      firstDayOfWeek,
      currentDate,
      locale,
      dateRange,
      weekStartShift,
      calendarFilters,
    }),
    [
      calendarFilters,
      currentDate,
      dateRange,
      firstDayOfWeek,
      locale,
      view,
      weekStartShift,
    ]
  );
};
