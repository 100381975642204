import { useCallback } from 'react';
import { Input } from '@chakra-ui/react';

import { useAutosizingInput } from '@bq/components/use-autosizing-input';
import { parseToNumber } from 'BootQuery/Assets/js/utils';

import { NumberRange, NumberSingleInputProps } from '../types';

export const NumberInputSingle = ({
  isNew,
  onChange,
  value,
}: NumberSingleInputProps) => {
  const handleChange = useCallback(
    (value: string) => {
      const parseValueToNumber = parseToNumber(value);
      if (onChange) {
        onChange(parseValueToNumber);
      }
    },
    [onChange]
  );

  const inputEl = useAutosizingInput<number | NumberRange>(value || 100);

  return (
    <Input
      ref={inputEl}
      type="number"
      value={value ?? undefined}
      onChange={(ev) => {
        handleChange(ev.target.value);
      }}
      minWidth="4"
      autoFocus={isNew}
    />
  );
};
