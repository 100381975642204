import { useMemo } from 'react';
import {
  differenceInMinutes,
  format,
  getDate,
  getMonth as gMonth,
  isSameMonth,
  isSameYear,
  Locale,
} from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CalendarDateRange, DayInfo, MonthsIndex } from './types';

export const getDayInfo = (date: Date, locale: Locale): DayInfo => {
  const nameOfDay = format(date, 'EEE', { locale });
  const numberOfDay = Number(format(date, 'd', { locale }));

  return {
    nameOfDay,
    numberOfDay,
  };
};

export const getDayNumber = (date: Date, locale: Locale): number => {
  return Number(format(date, 'd', { locale }));
};

export const getDayName = (date: Date, locale: Locale): string => {
  return format(date, 'EEE', { locale });
};

export const useMonthMap = () => {
  const { t } = useTranslation('global');

  return useMemo(
    () => ({
      0: t('global:months.january'),
      1: t('global:months.february'),
      2: t('global:months.march'),
      3: t('global:months.april'),
      4: t('global:months.may'),
      5: t('global:months.june'),
      6: t('global:months.july'),
      7: t('global:months.august'),
      8: t('global:months.september'),
      9: t('global:months.october'),
      10: t('global:months.november'),
      11: t('global:months.december'),
    }),
    [t]
  );
};

export const useGetMonth = (date: Date) => {
  const year = format(date, 'yyyy');

  const monthIdx = gMonth(date) as MonthsIndex;
  const monthMap = useMonthMap();

  return useMemo(
    () => `${monthMap[monthIdx]} ${year}`,
    [monthIdx, monthMap, year]
  );
};

export const useGetWeek = (dateRange: CalendarDateRange) => {
  const monthMap = useMonthMap();

  return useMemo(() => {
    const year = format(dateRange.start, 'yyyy');
    const sameMonth = isSameMonth(dateRange.start, dateRange.end);
    const sameYear = isSameYear(dateRange.start, dateRange.end);
    const monthStartIdx = gMonth(dateRange.start) as MonthsIndex;
    const dayNumberStart = getDate(dateRange.start);
    const dayNumberEnd = getDate(dateRange.end);
    const monthNameStart = monthMap[monthStartIdx];

    if (sameMonth && sameYear) {
      return `${dayNumberStart}. - ${dayNumberEnd}. ${monthNameStart} ${year}`;
    }
    const monthEndIdx = gMonth(dateRange.end) as MonthsIndex;

    const monthNameEnd = monthMap[monthEndIdx];
    const yearEnd = format(dateRange.end, 'yyyy');
    if (!sameMonth && sameYear) {
      return `${dayNumberStart}. ${monthNameStart} - ${dayNumberEnd}. ${monthNameEnd} ${year}`;
    }

    return `${dayNumberStart}. ${monthNameStart} ${year} - ${dayNumberEnd}. ${monthNameEnd} ${yearEnd}`;
  }, [dateRange.end, dateRange.start, monthMap]);
};

export const useGetDay = (date: Date, locale: Locale) => {
  const monthMap = useMonthMap();

  return useMemo(() => {
    const dayName = format(date, 'EEEE', { locale });
    const year = format(date, 'yyyy');
    const day = getDate(date);
    const monthIdx = gMonth(date) as MonthsIndex;
    const month = monthMap[monthIdx];

    return `${dayName}, ${day}. ${month} ${year}`;
  }, [date, locale, monthMap]);
};

export const useTz = () => {
  const timeZoneFormatted = useMemo(() => {
    const timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const date = new Date();
    const offsetMinutes = date.getTimezoneOffset();
    const offsetHours = Math.abs(offsetMinutes) / 60;
    const sign = offsetMinutes <= 0 ? '+' : '-';
    const offsetFormatted = `${sign}${String(offsetHours).padStart(2, '0')}:00`;

    return { tzName: timeZoneName, tzOffset: offsetFormatted };
  }, []);

  return timeZoneFormatted;
};

/**
 * Check if two dates are within a specified time interval
 * @param date1 - The first date
 * @param date2 - The second date
 * @param intervalMinutes - The time interval in minutes (e.g., 30 for 30 minutes)
 * @returns boolean - True if the dates are within the interval, false otherwise
 */
export const areDatesWithinIntervalMinutes = (
  date1: Date,
  date2: Date,
  intervalMinutes: number
): boolean => {
  const diff = Math.abs(differenceInMinutes(date1, date2));

  return diff <= intervalMinutes;
};
