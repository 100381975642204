import { ReactElement } from 'react';

import { FilterOperatorInput } from '../../../FilterOperator';
import { FilterProps } from '../../../types';
import { NumberRange } from '../types';
import { FilterNumberInput } from './FilterNumberInput';

export const NumberInput = ({
  value,
  onChange,
  operators,
  operator,
  onOperatorChange,
  onRemove,
}: FilterProps<number | NumberRange>): ReactElement => (
  <>
    {operators.length > 0 && (
      <FilterOperatorInput
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <FilterNumberInput
      value={value}
      onChange={onChange}
      onRemove={onRemove}
      operator={operator}
    />
  </>
);
