import { useMemo } from 'react';
import { addMinutes, set } from 'date-fns';

import { DateCellClickEvent } from '../types';

const roundToNearest15 = (minutes: number) => {
  return Math.round(minutes / 15) * 15;
};

export const useCalcRelativeStart = ({
  hasHour,
  topOffsetPercent,
  date,
}: DateCellClickEvent) => {
  return useMemo(() => {
    if (hasHour) {
      const hourPercent = topOffsetPercent / 100;
      const minutes = 60 * hourPercent;
      const roundMinutes = roundToNearest15(minutes);

      return addMinutes(date, roundMinutes);
    }

    return set(date, { hours: 12, minutes: 0 });
  }, [date, hasHour, topOffsetPercent]);
};
