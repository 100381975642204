import {
  CalendarDateRange,
  CalendarGridMap,
  CalendarItemSplitted,
} from '../types';
import { findAndReserveAvaliableSpace } from '../Views/Month/utils/find-and-reserve-avaliable-space';
import { getRawGridPositions } from '../Views/Month/utils/shared-utils';
import {
  CalendarItemWithAdditionalProps,
  CalendarLayoutItems,
} from '../Views/Month/utils/types';
import { getEventBoundaryDates } from './get-event-boundary-dates';

interface PopulateWeekParams {
  items: CalendarItemSplitted[];
  range: CalendarDateRange;
  weekSpacesMap: Record<string, number[]>;
  gridMap: CalendarGridMap;
  showMoreButtonMap: Record<string, number>;
  noOfItemsInEachColumn: Record<string, number>;
}
export const populateWeek = ({
  items,
  range,
  weekSpacesMap,
  gridMap,
  showMoreButtonMap,
  noOfItemsInEachColumn,
}: PopulateWeekParams) => {
  let wsm = weekSpacesMap;
  let hsm = showMoreButtonMap;
  let noiec = noOfItemsInEachColumn;

  const newItems = items.reduce<CalendarLayoutItems>((all, current) => {
    const dates = getEventBoundaryDates(
      current.startDate,
      current.endDate,
      range
    );
    if (!dates.isOverlapping) {
      return all;
    }
    const { avaliableSpace, updatedMap } = findAndReserveAvaliableSpace(
      dates.startDate,
      dates.endDate,
      wsm
    );

    const { endGridPos, startGridPos } = getRawGridPositions(
      gridMap,
      dates.startDate.formatted,
      dates.endDate.formatted
    );
    // We check if there is an avaliable space to insert a new item
    if (avaliableSpace !== null) {
      // If there is a space to insert a new item, we insert it
      // and update the map of spaces, so we know next time
      // not to insert an item in that space
      wsm = updatedMap;

      // we copy the provided info and it more props that
      // are used for layouting purposes
      const newItem: CalendarItemWithAdditionalProps = {
        ...current,
        overflowsLeft: dates.overflowsLeft,
        overflowsRight: dates.overflowsRight,
        calendarItemType: 'item',
        order: avaliableSpace,
        column: {
          start: startGridPos.column.start,
          end: endGridPos.column.end,
        },
        row: { start: startGridPos.row.start, end: endGridPos.row.end },
      };

      const prevCount = noiec[dates.startDate.formatted];
      noiec = {
        ...noiec,
        [dates.startDate.formatted]: prevCount + 1,
      };

      return [...all, newItem];
    }

    const prevHasMoreButton = hsm[dates.startDate.formatted];
    hsm = {
      ...hsm,
      [dates.startDate.formatted]: prevHasMoreButton + 1,
    };

    return all;
  }, []);

  return {
    newItems,
    showMoreButtonMap: hsm,
    weekSpacesMap: wsm,
    noOfItemsInEachColumn: noiec,
  };
};
