import { Box, Checkbox, HStack, VStack } from '@chakra-ui/react';

import { useCalendarCtx } from '@bq/components/Calendar';

import { EventProviderMap } from '../types';
import { useCalendarTypes } from './use-calendar-types';

interface Props {
  eventTypes: EventProviderMap;
}

export const CalendarTypeSelect = ({ eventTypes }: Props) => {
  const calendarTypes = useCalendarTypes(eventTypes);
  const {
    calendarFilters: [calendarFilters, setCalendarFilters],
  } = useCalendarCtx();

  return (
    <VStack w="full" px={4}>
      {calendarTypes.map((type) => (
        <Checkbox
          w="full"
          key={type.key}
          m={0}
          size="lg"
          colorScheme="brand"
          isChecked={calendarFilters[type.key]}
          onChange={(e) => {
            setCalendarFilters((prev) => ({
              ...prev,
              [type.key]: e.target.checked,
            }));
          }}
        >
          <HStack>
            <Box>{type.icon}</Box>
            <Box flex="1 1 auto" m={0}>
              {typeof type.label === 'function' ? type.label() : type.label}
            </Box>
          </HStack>
        </Checkbox>
      ))}
    </VStack>
  );
};
