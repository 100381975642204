import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ListingGrid, ListingGridItem } from '@bq/components/ListingGrid';
import { ContactModalProvider } from 'app/Modules/Telephony/Assets/components/ContactModal';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { PageWrapper } from 'BootQuery/Assets/components/PageWrapper';
import { ScrollRefProvider } from 'BootQuery/Assets/js/scroll-ctx';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';
import { useScrollContainerRef } from 'BootQuery/Assets/js/use-scroll-container-ref';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { CustomEventsProvider } from '../components/CustomEventsContext';
import { EventList } from '../components/EventList';
import { Sidebar } from '../components/Sidebar';

export const Events = (): ReactElement => {
  const { t } = useTranslation('Events');
  useAppTitle(t('Events:events_title'));

  const settingsLoaded = useLoadUserSettings('Events', {});
  const containerRef = useScrollContainerRef();

  return (
    <ScrollRefProvider scrollRef={containerRef}>
      <PageWrapper p={0}>
        <CustomEventsProvider>
          <ContactModalProvider>
            <ListingGrid>
              <ListingGridItem minWidth={250}>
                <Sidebar />
              </ListingGridItem>
              <ListingGridItem initialWidth="6fr" containerRef={containerRef}>
                {settingsLoaded ? <EventList /> : <LoadingPage />}
              </ListingGridItem>
            </ListingGrid>
          </ContactModalProvider>
        </CustomEventsProvider>
      </PageWrapper>
    </ScrollRefProvider>
  );
};
