import { PropsWithChildren } from 'react';
import { VStack } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';

import { useCalendarCtx } from '../CalendarCtx';

export const CalendarSidebar = ({ children }: PropsWithChildren) => {
  const {
    currentDate: [currentDate, setCurrentDate],
    locale,
  } = useCalendarCtx();

  return (
    <VStack w="full">
      <ReactDatePicker
        inline
        locale={locale}
        selected={currentDate}
        onChange={(date) => {
          if (date) {
            setCurrentDate(date);
          }
        }}
      />
      {children}
    </VStack>
  );
};
