import {
  addHours,
  endOfDay,
  endOfMonth,
  endOfWeek,
  Locale,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

import { CalendarDateRange, DaysOfWeek } from '../types';

export const generateMonthRange = (
  date: Date,
  weekStartsOn: DaysOfWeek,
  locale: Locale
): CalendarDateRange => {
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);
  const padStart = startOfWeek(startDate, { weekStartsOn, locale });
  const padEnd = endOfWeek(endDate, { weekStartsOn, locale });

  return { start: padStart, end: padEnd };
};

export const generateWeekRange = (
  date: Date,
  weekStartsOn: DaysOfWeek,
  locale: Locale,
  shift: number
): CalendarDateRange => {
  const startDate = startOfWeek(date, { weekStartsOn, locale });
  const endDate = endOfWeek(date, { weekStartsOn, locale });

  if (shift >= 1 || shift <= -1) {
    const startDateShifted = addHours(startDate, shift);
    const endDateShifted = addHours(endDate, shift);

    return { start: startDateShifted, end: endDateShifted };
  }

  return { start: startDate, end: endDate };
};

export const generateDayRange = (date: Date): CalendarDateRange => {
  return { start: startOfDay(date), end: endOfDay(date) };
};
