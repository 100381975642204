import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

import { AppointmentCalendarEvent } from '../../AppointmentEvent/types';

export const getCalendarAppointmentEvent = async (
  ID: number | string,
  params: GetOneParams
): Promise<AppointmentCalendarEvent> => {
  const { data } = await Api.get<AppointmentCalendarEvent>(
    `/api/events/calendarEvents/appointment/${ID}`,
    {
      params,
    }
  );

  return data;
};
