import { useMemo } from 'react';

import { SortValue } from '../Table/types';

export const useFallbackSort = (
  sortState: SortValue[],
  fallbackSort: SortValue
) => {
  return useMemo(() => {
    const hasFallbackSortVal = sortState.find((item) => {
      if (item.sortBy === fallbackSort.sortBy) {
        return true;
      }

      return false;
    });
    if (hasFallbackSortVal) {
      return sortState;
    }

    return [...sortState, fallbackSort];
  }, [fallbackSort, sortState]);
};
