import {
  addDays,
  differenceInMinutes,
  endOfDay,
  format,
  isBefore,
} from 'date-fns';

import {
  CalendarDateRange,
  CalendarItem,
  CalendarItemSplitted,
  CalendarItemsSplitByDay,
  EventBoundaryDates,
  ValidCalendarItemsSplitByDay,
} from '@bq/components/Calendar/types';
import { getEventBoundaryDates } from '@bq/components/Calendar/Utils/get-event-boundary-dates';

import { endOfDayShifted, isSameShiftedDay, startOfDayShifted } from './utils';

export const splitEventsIntoCalendarDays = (
  items: CalendarItem[],
  range: CalendarDateRange,
  shiftInHours: number
) => {
  return items.reduce<CalendarItemsSplitByDay>(
    (prev, current) => {
      const eventDates = getEventBoundaryDates(
        current.startDate,
        current.endDate,
        range
      );
      if (!eventDates.isOverlapping) {
        return prev;
      }
      const { endDate, startDate } = eventDates;
      const duration = differenceInMinutes(endDate.rawDate, startDate.rawDate);

      if (duration >= 1440 || current.isWholeDayEvent) {
        return { ...prev, wholeDayEvents: [...prev.wholeDayEvents, current] };
      }

      const hasDayChanged = !isSameShiftedDay(
        startDate.rawDate,
        endDate.rawDate,
        shiftInHours
      );

      if (hasDayChanged) {
        const split = splitEventsIntoTwoDays(
          current,
          eventDates,
          prev,
          shiftInHours
        );

        return split;
      }

      const startOfDay = startOfDayShifted(startDate.rawDate, shiftInHours);
      const endOfDay = endOfDayShifted(endDate.rawDate, shiftInHours);
      const key = getKey(startOfDay, endOfDay);

      const previousInstance = readPreviousInstance(
        prev.fixedDurationEvents,
        key
      );
      const itemDuration = differenceInMinutes(
        endDate.rawDate,
        startDate.rawDate
      );

      return {
        ...prev,
        fixedDurationEvents: {
          ...prev.fixedDurationEvents,
          [key]: [
            ...previousInstance,
            {
              ...current,
              startDate: startDate.rawDate,
              endDate: endDate.rawDate,
              duration: itemDuration,
            },
          ],
        },
      };
    },
    { wholeDayEvents: [], fixedDurationEvents: {} }
  );
};

export const splitEventsIntoTwoDays = (
  item: CalendarItem,
  { startDate, endDate }: EventBoundaryDates,
  prev: CalendarItemsSplitByDay,
  hourShift = 0
): CalendarItemsSplitByDay => {
  const firstHalf = {
    start: startDate.rawDate,
    end: endOfDayShifted(startDate.rawDate, hourShift),
  };
  // We make sure the end date is not somehow in 2 days instead of 1,
  // This is a rare case but could in theory happen with DST
  const ensureSecondDayIsDayAfter = addDays(
    startOfDayShifted(startDate.rawDate, hourShift),
    1
  );

  const endOfNextDay = endOfDay(ensureSecondDayIsDayAfter);
  const isBeforeEndOfNextDay = isBefore(endDate.rawDate, endOfNextDay);

  const clampEndDate = isBeforeEndOfNextDay ? endDate.rawDate : endOfNextDay;

  const secondHalf = {
    start: ensureSecondDayIsDayAfter,
    end: clampEndDate,
  };

  const firstHalfKey = getKey(
    startOfDayShifted(firstHalf.start, hourShift),
    firstHalf.end
  );
  const secondHalfKey = getKey(
    secondHalf.start,
    endOfDayShifted(secondHalf.end, hourShift)
  );

  const readPreviousFirstDay = readPreviousInstance(
    prev.fixedDurationEvents,
    firstHalfKey
  );
  const readPreviousSecondDay = readPreviousInstance(
    prev.fixedDurationEvents,
    secondHalfKey
  );
  const firstItemDuration = differenceInMinutes(firstHalf.end, firstHalf.start);
  const firstItem: CalendarItemSplitted = {
    ...item,
    startDate: firstHalf.start,
    endDate: firstHalf.end,
    duration: firstItemDuration,
    splitInfo: { instanceOf: item, numberOfChunksSplitInto: 2 },
  };

  const secondItemDuration = differenceInMinutes(
    secondHalf.end,
    secondHalf.start
  );
  const secondItem: CalendarItemSplitted = {
    ...item,
    startDate: secondHalf.start,
    endDate: secondHalf.end,
    duration: secondItemDuration,
    splitInfo: { instanceOf: item, numberOfChunksSplitInto: 2 },
  };

  return {
    ...prev,
    fixedDurationEvents: {
      ...prev.fixedDurationEvents,
      [firstHalfKey]: [...readPreviousFirstDay, firstItem],
      [secondHalfKey]: [...readPreviousSecondDay, secondItem],
    },
  };
};

export const readPreviousInstance = (
  itemSplitByDay: ValidCalendarItemsSplitByDay,
  dayKey: string
) => {
  // We try to read the previous instance if it exists
  const previousInstance = itemSplitByDay[dayKey] ?? [];

  return previousInstance;
};

const getKey = (start: Date, end: Date) => {
  const startToKey = format(start, 'yyyy-MM-dd/HH:mm');
  const endToKey = format(end, 'yyyy-MM-dd/HH:mm');

  return `${startToKey}|${endToKey}`;
};
