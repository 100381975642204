import i18n from 'BootQuery/Assets/js/i18n';

import { NumberInput } from './Input';
import { NumberTag } from './Tag';
import { NumberFilter, NumberUserDef } from './types';
import { getValueFromJson, parseFilter } from './utils';

export function makeNumberFilter(filterDef: NumberUserDef): NumberFilter {
  return {
    tagComponent: NumberTag,
    inputComponent: NumberInput,
    operators: () => [
      { operator: 'eq', display: i18n.t('global:operators.is') },
      { operator: 'neq', display: i18n.t('global:operators.is_not') },
      { operator: 'gt', display: i18n.t('global:operators.greater_than') },
      { operator: 'gte', display: i18n.t('global:operators.greater_or_equal') },
      { operator: 'lt', display: i18n.t('global:operators.less_than') },
      { operator: 'lte', display: i18n.t('global:operators.less_or_equal') },
      { operator: 'between', display: i18n.t('global:operators.between') },
    ],
    defaultValue: 0,
    fromJSON: getValueFromJson,
    fromQueryString: parseFilter,
    ...filterDef,
  };
}
