import { ReactElement, Suspense, useCallback, useRef } from 'react';
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaList } from 'react-icons/fa';

import { FilterTypes } from '../../FilterBar/types';
import { LoaderScale } from '../../Loader';
import { IOverviewEditorItem, OverviewGetter } from '../types';
import {
  EditorContentHandle,
  OverviewEditorModalContent,
} from './OverviewEditorModalContent';

export interface OverviewEditorModalProps {
  isOpen: boolean;
  onClose: () => void;
  filterTypes: FilterTypes;
  value: IOverviewEditorItem[] | OverviewGetter;
  onChange: (value: IOverviewEditorItem[]) => void;
  withGroupVisibility?: boolean;
  queryKey: string;
}

export const OverviewEditorModal = (
  props: OverviewEditorModalProps
): ReactElement => {
  const { t } = useTranslation();
  const { onClose } = props;
  const bg = useColorModeValue('brand.background', 'brand.backgroundDark');
  const colorValue = useColorModeValue('brand.darkGray', 'brand.lightGray');

  const contentRef = useRef<EditorContentHandle>(null);

  const cancel = useCallback(() => {
    if (contentRef.current) {
      contentRef.current.cancel();
    } else {
      onClose();
    }
  }, [onClose]);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={cancel}
      size="6xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent bg={bg} color={colorValue}>
        <ModalHeader display="flex" alignItems="center">
          <FaList />
          &nbsp;
          {t('global:overviews.edit_overviews')}
        </ModalHeader>
        <ModalCloseButton />
        <Suspense
          fallback={
            <Flex justifyContent="center" py="6">
              <LoaderScale height={32} />
            </Flex>
          }
        >
          <OverviewEditorModalContent {...props} ref={contentRef} />
        </Suspense>
      </ModalContent>
    </Modal>
  );
};
