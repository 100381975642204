import i18n from 'BootQuery/Assets/js/i18n';

import { SoftphoneError } from './types';

export interface FormattedSoftphoneError {
  title: string;
  description: string;
}

export function formatSoftphoneError(
  errType: SoftphoneError
): FormattedSoftphoneError {
  switch (errType) {
    case 'no_audio_device':
      return {
        title: i18n.t('Telephony:softphone_error.no_audio_device_title'),
        description: i18n.t(
          'Telephony:softphone_error.no_audio_device_description'
        ),
      };
    case 'no_audio_device_permission':
      return {
        title: i18n.t(
          'Telephony:softphone_error.no_audio_device_permission_title'
        ),
        description: i18n.t(
          'Telephony:softphone_error.no_audio_device_permission_description'
        ),
      };
    case 'ice_connection_failed':
      return {
        title: i18n.t('Telephony:softphone_error.ice_connection_failed_title'),
        description: i18n.t(
          'Telephony:softphone_error.ice_connection_failed_description'
        ),
      };
    default:
      console.warn('Unknown softphone error:', errType);

      return {
        title: errType,
        description: '',
      };
  }
}
