import { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';

import { FilterName } from '../../../FilterName';
import { FilterTagOperator } from '../../../FilterOperator';
import { FilterProps } from '../../../types';
import { NumberRange } from '../types';
import { FilterNumberTag } from './FilterNumberTag';

export const NumberTag = ({
  name,
  value,
  onChange,
  operators,
  operator,
  onOperatorChange,
  onRemove,
  isNew,
}: FilterProps<number | NumberRange>): ReactElement => (
  <Flex>
    <FilterName name={name} />
    {operators.length > 0 && (
      <FilterTagOperator
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <FilterNumberTag
      operator={operator}
      value={value}
      onChange={onChange}
      onRemove={onRemove}
      isNew={isNew}
    />
  </Flex>
);
