import { ReactElement } from 'react';

import { type IOverviewEditorItem } from '@bq/components/Overviews';
import { Select, SelectOption } from '@bq/components/SelectWrappers';

export type OverviewOption = SelectOption<
  string,
  { overview: IOverviewEditorItem }
>;

interface Props {
  options: OverviewOption[];
  value: OverviewOption | null;
  onChange: (value: OverviewOption | null) => void;
}

export const OverviewSelect = ({
  options,
  value,
  onChange,
}: Props): ReactElement => {
  return <Select options={options} value={value} onChange={onChange} />;
};

export function overviewToOption(
  overview: IOverviewEditorItem
): OverviewOption {
  return {
    label: overview.title,
    value: overview.slug,
    overview,
  };
}
