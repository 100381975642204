import { useMemo } from 'react';
import { Center, Spinner, VStack } from '@chakra-ui/react';

import { useCalendarCtx } from '../../CalendarCtx';
import { DayItem } from '../../SharedComponents/DayItem';
import { HourItem } from '../../SharedComponents/HourItem';
import { NowIndicator } from '../../SharedComponents/NowIndicator';
import { TallCalendarHourBox } from '../../SharedComponents/TallCalendarHourBox';
import {
  TallCalendarHour,
  TallCalendarHourTrack,
  TallCalendarItemGrid,
  TallCalendarMainContainer,
  TallCalendarMainLayout,
  TallCalendarTableEmptyCorner,
} from '../../SharedComponents/TallCalendarLayoutComponents';
import { TallCalendarWholeDayBox } from '../../SharedComponents/TallCalendarWholeDayBox';
import { TimezoneBox } from '../../SharedComponents/TimezoneBox';
import { useCalendarColors } from '../../use-calendar-colors';
import { useIsNowInsideRange } from '../../use-is-now-inside-range';
import { useMaxElementsPerCell } from '../../Utils/use-max-elements-per-cell';
import { NUMBER_OF_HOURS_IN_DAY } from '../Week/week-constants';
import { DayHeader } from './DayHeader';
import { useSetupDayCalendar } from './utils/use-setup-day-calendar';

export const DayView = () => {
  const { borders } = useCalendarColors();
  const {
    disclosureState: { isOpen },
    dateRange,
    isLoading,
  } = useCalendarCtx();

  const { day, hourItems, localizedHours, weekGridMapWithDays, weekItems } =
    useSetupDayCalendar();

  const { ref } = useMaxElementsPerCell();
  const isNowInsideRange = useIsNowInsideRange(dateRange);

  const multiDayItemBoxHeight = useMemo(() => {
    if (isOpen) {
      const calcHeight = weekItems.items.length * 21;
      const height = Math.max(calcHeight, 320);

      return `${height}px`;
    }

    return '120px';
  }, [isOpen, weekItems.items.length]);

  return (
    <TallCalendarMainContainer spacing={0} overflow="scroll" pos="relative">
      <TallCalendarMainLayout>
        <TallCalendarHourTrack
          // 60px is header
          // then we have non fixed duration item box
          // then we have hour boxes
          // thankfully there isn't more/less than 24 hours
          // this might change tho
          gridTemplateRows={`40px ${multiDayItemBoxHeight} repeat(${NUMBER_OF_HOURS_IN_DAY}, 48px)`}
        >
          <TallCalendarTableEmptyCorner />

          <TimezoneBox />
          {localizedHours.map((item) => (
            <TallCalendarHour key={item.key}>
              {item.localizedHour}
            </TallCalendarHour>
          ))}
        </TallCalendarHourTrack>
        <VStack spacing={0}>
          <DayHeader />

          <TallCalendarItemGrid
            w="full"
            // You think this is repeat 7, 1fr. Don't you?
            // Check day-constants.ts for a rant on this
            gridTemplateColumns={`repeat(${day.length}, 1fr)`}
            // Check above
            gridTemplateRows={`${multiDayItemBoxHeight} repeat(${NUMBER_OF_HOURS_IN_DAY}, 48px)`}
            borderColor={borders}
          >
            {/** First we layout the header where days and numbers are */}
            {/** Then we layout the boxes for whole day events */}
            {day.map((day, idx) => {
              return (
                <TallCalendarWholeDayBox
                  key={`wholeDayEventsBox-${day.key}`}
                  day={day}
                  gridMap={weekGridMapWithDays}
                  measureRef={idx === 0 ? ref : undefined}
                />
              );
            })}
            {/** Then we layout the boxes for events with fixed duration */}
            {localizedHours.map((hour) => {
              return day.map((day) => {
                return (
                  <TallCalendarHourBox
                    key={`fixedDurationEventsBox-${day.key}/${hour.key}`}
                    day={day}
                    gridMap={weekGridMapWithDays}
                    hour={hour}
                  />
                );
              });
            })}
            {isNowInsideRange && <NowIndicator gridMap={weekGridMapWithDays} />}
            {hourItems.fixedDurationEvents.map((item, idx) => (
              <HourItem
                key={item.splitInfo ? `${item.ID}-${idx}` : item.ID}
                {...item}
                idx={idx}
              />
            ))}
            {weekItems.items.map((item, idx) => (
              <DayItem
                {...item}
                idx={idx}
                key={`${item.calendarItemType}-${idx}`}
              />
            ))}
          </TallCalendarItemGrid>
        </VStack>
      </TallCalendarMainLayout>
      {isLoading && (
        <Center
          pos="absolute"
          top={0}
          right={0}
          w="full"
          h="100%"
          bg="transparent"
          zIndex={4}
        >
          <Spinner size="xl" />
        </Center>
      )}
    </TallCalendarMainContainer>
  );
};
