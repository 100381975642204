import { addMinutes, addSeconds } from 'date-fns';
import { FaListAlt } from 'react-icons/fa';

import { CalendarItem } from '@bq/components/Calendar';

import { TicketCalendarEvent } from './types';

const POINT_EVENT_DURATION = 30; // minutes

const getTicketEndDate = (
  startDate: Date,
  endDate?: string,
  duration?: number
) => {
  if (endDate) {
    return new Date(endDate);
  }
  if (duration) {
    return addSeconds(startDate, duration);
  }

  return addMinutes(startDate, POINT_EVENT_DURATION);
};

export const ticketToCalendarEvent = (
  event: TicketCalendarEvent
): CalendarItem => {
  const sd = new Date(event.startDate);
  const ed = getTicketEndDate(sd, event.endDate, event.durationSeconds);

  return {
    ID: `${event.type}-${event.id}`,
    title: event.data.title,
    color: event.data.state.color,
    isWholeDayEvent: false,
    startDate: sd,
    endDate: ed,
    additionalProps: { ...event.data },
    type: 'ticket',
    icon: <FaListAlt />,
  };
};
