import { useMemo } from 'react';
import { Center, Spinner, VStack } from '@chakra-ui/react';

import { useCalendarCtx } from '../../CalendarCtx';
import { DayItem } from '../../SharedComponents/DayItem';
import { HourItem } from '../../SharedComponents/HourItem';
import { NowIndicator } from '../../SharedComponents/NowIndicator';
import { TallCalendarHourBox } from '../../SharedComponents/TallCalendarHourBox';
import { TallCalendarItemHeader } from '../../SharedComponents/TallCalendarItemHeader';
import {
  TallCalendarHour,
  TallCalendarHourTrack,
  TallCalendarItemGrid,
  TallCalendarMainContainer,
  TallCalendarMainLayout,
  TallCalendarTableEmptyCorner,
} from '../../SharedComponents/TallCalendarLayoutComponents';
import { TallCalendarWholeDayBox } from '../../SharedComponents/TallCalendarWholeDayBox';
import { TimezoneBox } from '../../SharedComponents/TimezoneBox';
import { useIsNowInsideRange } from '../../use-is-now-inside-range';
import { useMaxElementsPerCell } from '../../Utils/use-max-elements-per-cell';
import { useSetupWeekCalendar } from './utils/use-setup-week-calendar';
import { NUMBER_OF_HOURS_IN_DAY } from './week-constants';
import { WeekHeader } from './WeekHeader/WeekHeader';

export const WeekView = () => {
  const {
    disclosureState: { isOpen },
    dateRange,
    isLoading,
  } = useCalendarCtx();

  const { hourItems, localizedHours, week, weekItems, weekGridMapWithDays } =
    useSetupWeekCalendar();

  const { ref } = useMaxElementsPerCell();
  const isNowInsideRange = useIsNowInsideRange(dateRange);

  // I tried to make this dynamic but couldn't quite hit the logic
  // because changes went into infinite loops
  // This looks okay so I'm leaving it for now

  // If we allow more items to overflow we will need less height
  // If we have less height we need to overflow more items
  // If we allow more items to overflow we will need less height
  // ...

  // If u have ideas u can take weekItems.maxOverflow and weekItems.maxReservedItems
  // They are the current number  of items shown/overflowing
  // That changes if you change this height :)
  const multiDayItemBoxHeight = useMemo(
    () => (isOpen ? '320px' : '120px'),
    [isOpen]
  );

  return (
    <TallCalendarMainContainer spacing={0} overflow="scroll" pos="relative">
      <TallCalendarMainLayout>
        <TallCalendarHourTrack
          // 60px is header
          // then we have non fixed duration item box
          // then we have hour boxes
          // thankfully there isn't more/less than 24 hours
          // this might change tho

          gridTemplateRows={`100px ${multiDayItemBoxHeight} repeat(${NUMBER_OF_HOURS_IN_DAY}, 48px)`}
        >
          <TallCalendarTableEmptyCorner />
          <TimezoneBox />
          {localizedHours.map((item) => (
            <TallCalendarHour key={item.key}>
              {item.localizedHour}
            </TallCalendarHour>
          ))}
        </TallCalendarHourTrack>
        <VStack spacing={0}>
          <WeekHeader />
          <TallCalendarItemGrid
            w="full"
            // You think this is repeat 7, 1fr. Don't you?
            // Check week-constants.ts for a rant on this
            gridTemplateColumns={`repeat(${week.length}, 1fr)`}
            // Check above
            gridTemplateRows={`60px ${multiDayItemBoxHeight} repeat(${NUMBER_OF_HOURS_IN_DAY}, 48px)`}
          >
            {/** First we layout the header where days and numbers are */}
            {week.map((day, idx) => (
              <TallCalendarItemHeader day={day} idx={idx} key={day.key} />
            ))}
            {/** Then we layout the boxes for whole day events */}
            {week.map((day, idx) => {
              return (
                <TallCalendarWholeDayBox
                  key={`wholeDayEventsBox-${day.key}`}
                  day={day}
                  gridMap={weekGridMapWithDays}
                  measureRef={idx === 0 ? ref : undefined}
                />
              );
            })}
            {/** Then we layout the boxes for events with fixed duration */}
            {localizedHours.map((hour) => {
              return week.map((day) => {
                return (
                  <TallCalendarHourBox
                    key={`fixedDurationEventsBox-${day.key}/${hour.key}`}
                    day={day}
                    gridMap={weekGridMapWithDays}
                    hour={hour}
                  />
                );
              });
            })}
            {isNowInsideRange && <NowIndicator gridMap={weekGridMapWithDays} />}
            {hourItems.fixedDurationEvents.map((item, idx) => (
              <HourItem
                key={item.splitInfo ? `${item.ID}-${idx}` : item.ID}
                {...item}
                idx={idx}
              />
            ))}
            {weekItems.items.map((item, idx) => (
              <DayItem
                {...item}
                idx={idx}
                key={`${item.calendarItemType}-${idx}`}
              />
            ))}
          </TallCalendarItemGrid>
        </VStack>
      </TallCalendarMainLayout>
      {isLoading && (
        <Center
          pos="absolute"
          top={0}
          right={0}
          w="full"
          h="100%"
          bg="transparent"
          zIndex={4}
        >
          <Spinner size="xl" />
        </Center>
      )}
    </TallCalendarMainContainer>
  );
};
