import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import {
  FilterExpression,
  filterExpression,
  FilterTypes,
  filterTypesWithGroup,
} from '@bq/components/FilterBar';
import { getFormDefinition } from '@bq/components/FormEditor';
import { IOverviewEditorItem } from '@bq/components/Overviews';
import { getFilterTypes as getPersonFilters } from 'app/Modules/Phonebook/Assets/components/PersonList';
import { getCardPeople } from 'app/Modules/Phonebook/Assets/components/PersonList/get-people';

import { ContactItem } from '../../HopsOperator/HopsContacts/types';

export interface GenerateCardsOptions {
  color: string | null;
  suffixMode: string;
  overview: IOverviewEditorItem | null;
}

export type GenerateCards = (
  options: GenerateCardsOptions
) => Promise<ContactItem[]>;

export function useGeneateCards(): GenerateCards {
  const queryClient = useQueryClient();

  return useCallback(
    async ({ color, suffixMode, overview }: GenerateCardsOptions) => {
      const personForm = await getFormDefinition(
        queryClient,
        'Phonebook.person'
      );
      const filterTypes = getPersonFilters(personForm);

      const filters = overview
        ? await filtersForOverview(overview, filterTypes)
        : [];

      console.log('Getting people: ', overview, filters);

      const { data: people } = await getCardPeople({
        fields: [
          'ID',
          'firstName',
          'lastName',
          [
            'phoneNumbers',
            ['phoneNumber.phoneNumberE164', ['numberType', ['ID', 'name']]],
          ],
          'user.pbxUser.phoneNumber.phoneNumberE164',
        ],
        sort: ['firstName', 'lastName'],
        filters,
        limit: 'null',
        withCount: false,
      });

      console.log('People: ', people);
      const cards: ContactItem[] = people.reduce((cards, person) => {
        const hasMultipleNumbers = person.phoneNumbers.length > 1;
        const personItems: ContactItem[] = person.phoneNumbers.map(
          (phoneNumber) => {
            if (!suffixMode) {
              throw new Error('No suffix mode');
            }

            const suffix = needsSuffix(suffixMode, hasMultipleNumbers)
              ? phoneNumber.numberType.name.substring(0, 3)
              : null;

            return {
              color,
              suffix,
              contact: {
                ID: person.ID,
                type: 'person',
                name: `${person.firstName} ${person.lastName}`.trim(),
                phoneNumber: phoneNumber.phoneNumber.phoneNumberE164,
              },
            };
          }
        );

        return [...cards, ...personItems];
      }, [] as ContactItem[]);

      return cards;
    },
    [queryClient]
  );
}

async function filtersForOverview(
  overview: IOverviewEditorItem,
  filterTypes: FilterTypes
): Promise<FilterExpression[]> {
  return filterExpression(
    filterTypesWithGroup(filterTypes),
    [{ filter: '$group', operator: '$and', value: overview.filters }],
    ''
  );
}

function needsSuffix(suffixMode: string, hasMultipleNumbers: boolean): boolean {
  if (suffixMode === 'always') {
    return true;
  }
  if (suffixMode === 'never') {
    return false;
  }

  if (suffixMode === 'multiple') {
    return hasMultipleNumbers;
  }

  throw new Error(`Unknown suffix mode: ${suffixMode}`);
}
