import { useMemo } from 'react';
import { Center, Grid, Spinner, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import { useCalendarCtx } from '../../CalendarCtx';
import { useCalendarColors } from '../../use-calendar-colors';
import { useGenerateHeader } from '../../use-generate-header';
import { useMaxElementsPerCell } from '../../Utils/use-max-elements-per-cell';
import { MonthDateCell } from './MonthDayCell';
import { MonthDayHeader } from './MonthDayHeader';
import { MonthHeader } from './MonthHeader';
import { MonthViewItem } from './MonthViewItem';
import { MonthViewShowMoreButton } from './MonthViewShowMoreButton';
import { useCalendarGridDateMap } from './utils/calendar-grid-map';
import { getMonthItems } from './utils/month-items';
import { generateMonth } from './utils/shared-utils';

export const MonthView = () => {
  const {
    currentDate: [currentDate],
    firstDayOfWeek: [weekStartsOn],
    dateRange,
    items,
    maxItemsPerCell: [maxItems],
    isLoading,
  } = useCalendarCtx();

  const monthArray = useMemo(() => {
    return generateMonth(dateRange);
  }, [dateRange]);

  const noWeeks = useMemo(() => {
    const minLen = monthArray.length ?? 0;

    return Math.ceil(minLen / 7);
  }, [monthArray.length]);

  const getFirstWeek = useMemo(() => monthArray.slice(0, 7), [monthArray]);

  const headerElements = useGenerateHeader(getFirstWeek);

  const { borders } = useCalendarColors();

  const gridMap = useCalendarGridDateMap(monthArray);

  const { ref } = useMaxElementsPerCell();

  const monthItems = useMemo(() => {
    if (maxItems === 0) {
      return [];
    }

    return getMonthItems({
      gridMap,
      items,
      maxItems,
      range: dateRange,
      weekStartsOn,
    });
  }, [dateRange, gridMap, items, maxItems, weekStartsOn]);

  return (
    <VStack h="full" w="full" m={0} pos="relative" spacing={0}>
      <MonthHeader />

      <Grid
        m={0}
        w="full"
        flex="1 1 auto"
        gridTemplateColumns="repeat(7, 1fr)"
        gridTemplateRows={`20px repeat(${noWeeks}, 1fr)`}
        gridGap="1px"
        rowGap="1px"
        borderX="1px solid"
        borderColor={borders}
      >
        {headerElements.map((day, idx) => {
          const startIdx = idx + 1; // Grid index start at 0
          const endIdx = startIdx + 1; // Ends at index +1

          return (
            <MonthDayHeader
              key={day}
              day={day}
              col={`${startIdx} / ${endIdx}`}
              row="1 / 2" // Header is always in first row
            />
          );
        })}
        {monthArray.map((date, idx) => {
          const formatDate = format(date, 'yyyy-MM-dd');
          const pos = gridMap[formatDate];
          if (!pos) {
            throw Error('Unknown item detected in calendar');
          }

          return (
            <MonthDateCell
              measureRef={idx === 0 ? ref : undefined}
              key={date.toISOString()}
              date={date}
              currentMonth={currentDate}
              column={`${pos.column.start} / ${pos.column.end}`}
              row={`${pos.row.start} / ${pos.row.end}`}
            />
          );
        })}
        {monthItems.map((item, idx) => {
          if (item.calendarItemType === 'item') {
            return <MonthViewItem {...item} idx={idx} key={`item-${idx}`} />;
          }

          return <MonthViewShowMoreButton {...item} key={`show-more-${idx}`} />;
        })}
      </Grid>
      {isLoading && (
        <Center
          pos="absolute"
          top={0}
          right={0}
          w="full"
          h="100%"
          bg="transparent"
          zIndex={4}
        >
          <Spinner size="xl" />
        </Center>
      )}
    </VStack>
  );
};
