import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

import { TicketCalendarEvent } from '../EventTypes/Ticket';

export const getTicketCalendarEvent = async (
  ID: number | string,
  params: GetOneParams
): Promise<TicketCalendarEvent> => {
  const { data } = await Api.get<TicketCalendarEvent>(
    `/api/events/calendarEvents/ticket/${ID}`,
    {
      params,
    }
  );

  return data;
};
