import { useCalendarCtx } from './CalendarCtx';
import { DayView } from './Views/Day/DayView';
import { MonthView } from './Views/Month/MonthView';
import { WeekView } from './Views/Week/WeekView';

export const CalendarMain = () => {
  const {
    view: [view],
  } = useCalendarCtx();

  return (
    <>
      {view === 'month' && <MonthView />}
      {view === 'week' && <WeekView />}
      {view === 'day' && <DayView />}
    </>
  );
};
