import { Jsonish } from '@bq/components/type-util';
import { parseToNumber } from 'BootQuery/Assets/js/utils';

import { ParseQueryString } from '../../types';
import { NumberRange } from './types';

export const parseFilter = (
  value: ParseQueryString | Jsonish,
  operator: string | null
): number | null | NumberRange => {
  if (!value) {
    return null;
  }
  if (operator === 'between') {
    if (typeof value !== 'object') {
      return null;
    }
    if (!('from' in value && 'to' in value)) {
      return null;
    }
    if (typeof value.from !== 'number' || typeof value.to !== 'number') {
      return null;
    }

    return { from: value.from, to: value.to };
  }
  if (typeof value === 'string') {
    return parseToNumber(value);
  }
  if (typeof value === 'number') {
    return value;
  }

  return null;
};
export function isNumberRange(
  value: Jsonish | NumberRange | null
): value is NumberRange {
  if (!value || !(value instanceof Object)) {
    return false;
  }

  const hasFrom = 'from' in value && typeof value.from === 'number';
  const hasTo = 'to' in value && typeof value.to === 'number';

  return hasFrom && hasTo;
}
export const getValueFromJson = (
  value: Jsonish,
  operator: string | null | undefined
): number | null | NumberRange => {
  if (value === null || value === undefined) {
    return null;
  }

  if (typeof value === 'number' || isNumberRange(value)) {
    return value;
  }

  return parseFilter(value, operator ?? null);
};
