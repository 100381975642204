import { ReactNode } from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaList } from 'react-icons/fa';

import { FilterTypes } from '../FilterBar';
import { IOverviewEditorItem, OverviewEditorModal } from '../Overviews';

interface Props {
  onChange: (value: IOverviewEditorItem[]) => void;
  value: IOverviewEditorItem[];
  filterTypes: FilterTypes;
  children?: ReactNode;
  queryKey: string;
}
export const OverviewButton = ({
  onChange,
  value = [],
  filterTypes,
  children,
  queryKey,
}: Props) => {
  const { t } = useTranslation('global');
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Button type="button" onClick={onOpen} leftIcon={<FaList />}>
        {children ?? t('global:edit_overviews')}
      </Button>
      <OverviewEditorModal
        queryKey={queryKey}
        isOpen={isOpen}
        onClose={onClose}
        filterTypes={filterTypes}
        value={value}
        onChange={onChange}
      />
    </>
  );
};
