import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';

import { SimpleContactDisplay } from '../../../../../../BootQuery/Assets/components/ContactDisplay/SimpleContactDisplay';
import { AgentContactDisplayFields } from './AgentContactDisplayFields';

interface AgentProps {
  contactID: number;
  contactName: string;
  contactType: ContactType;
  valueFontWeight?: string | undefined;
}

export const AgentContactDisplay = ({
  contactID,
  contactType,
  contactName,
  valueFontWeight,
}: AgentProps) => {
  return (
    <SimpleContactDisplay
      zIndex={2}
      valueFontWeight={valueFontWeight}
      contactID={contactID}
      contactType={contactType}
      contactName={contactName}
    >
      <AgentContactDisplayFields
        contactID={contactID}
        contactType={contactType}
      />
    </SimpleContactDisplay>
  );
};
