import { ReactElement, useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useModal } from '@bq/components/UseModal';

import { ContactItem } from '../../HopsOperator/HopsContacts/types';
import { CardGenerator } from './CardGenerator';

interface Props {
  setCards?: (cards: ContactItem[]) => void;
  defaultColor?: string | null;
}

export const GenerateCardsButton = (props: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { addModal } = useModal();

  const openGenerator = useCallback(() => {
    addModal({
      children: <CardGenerator {...props} />,
      componentProps: {
        modal: {
          size: 'lg',
          scrollBehavior: 'inside',
          closeOnOverlayClick: true,
        },
      },
    });
  }, [addModal, props]);

  return (
    <Button size="sm" onClick={openGenerator}>
      {t('Telephony:operator.generate_contacts')}...
    </Button>
  );
};
