import { Modifications } from '@bq/components/SettingsCRUD';
import { ModificationsNoID } from '@bq/components/SettingsCRUD/types';
import { tabs } from 'app/Modules/Telephony/Assets/store';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';
import { parseID } from 'BootQuery/Assets/js/utils';

import { patchTicket } from '../Api/ticket/patchTicket';
import {
  ModifyTicketShare,
  PatchTicket,
  PatchTicketLinks,
  PatchTicketReminder,
  PatchTicketShares,
  TicketForm,
  TicketLink,
  TicketReminder,
  TicketShare,
} from '../types';
import { CallbackTicket } from './Dependencies/types';
import {
  contactForBackend,
  shareForBackendCreate,
  shareForBackendUpdate,
} from './util';

export const handleEditSubmit = async <
  T extends CallbackTicket = CallbackTicket,
>(
  data: Partial<TicketForm>,
  params?: GetListingParams,
  parentID?: number
): Promise<T> => {
  const {
    assignedUser,
    customer,
    contact,
    group,
    links,
    priority,
    reminders,
    reportMethod,
    reportedAt,
    respondUntil,
    resolveUntil,
    scheduledFor,
    shares,
    state,
    tags,
    ticketType,
    title,
    data: formData,
    ID,
    subtickets,
  } = data;

  let parsedLinks: ModificationsNoID<{ otherTicketID: number }> | undefined;
  if (links) {
    const { defaults: _, ...other } = links;
    const typeCastOther = other as Modifications<TicketLink>;
    parsedLinks = {
      add: typeCastOther.add.map((item) => ({
        otherTicketID: item.otherTicket.ID,
      })),
      del: typeCastOther.del,
      upd: Object.entries(typeCastOther.upd).reduce(
        (all: PatchTicketLinks['upd'], current) => {
          return {
            ...all,
            [current[0]]: { otherTicketID: current[1].otherTicket?.ID },
          };
        },
        {} as PatchTicketLinks['upd']
      ),
    };
  }

  let parsedReminders:
    | ModificationsNoID<
        Omit<TicketReminder, 'newState' | 'ID' | 'done'> & {
          newStateID: number | null;
        }
      >
    | undefined;
  if (reminders) {
    const { defaults: _, ...other } = reminders;
    const typeCastOther = other as Modifications<TicketReminder>;
    parsedReminders = {
      add: typeCastOther.add.map((item) => ({
        title: item.title,
        description: item.description,
        newStateID: item.newState?.ID ?? null,
        timestamp: item.timestamp,
      })),
      del: typeCastOther.del,
      upd: Object.entries(typeCastOther.upd).reduce(
        (all: PatchTicketReminder['upd'], item) => {
          return {
            ...all,
            [item[0]]: {
              title: item[1].title,
              description: item[1].description,
              newStateID: item[1].newState?.ID ?? null,
              timestamp: item[1].timestamp,
            },
          };
        },
        {} as PatchTicketReminder['upd']
      ),
    };
  }

  let parsedShares: ModificationsNoID<ModifyTicketShare> | undefined;
  if (shares) {
    const { defaults: _, ...other } = shares;
    const typeCastOther = other as Modifications<TicketShare>;
    parsedShares = {
      add: typeCastOther.add
        .filter((share) => !!share.shareWith)
        .map(shareForBackendCreate),
      del: typeCastOther.del,
      upd: Object.entries(typeCastOther.upd).reduce(
        (all: PatchTicketShares['upd'], [shareID, share]) => ({
          ...all,
          [shareID]: shareForBackendUpdate(share),
        }),
        {} as PatchTicketShares['upd']
      ),
    };
  }

  // there is basically 0 chance this will happen here but to be sure
  const parsePriorityID = parseID(priority?.ID);

  const submitData: Partial<PatchTicket> = {
    assignedUserID: assignedUser?.ID ?? null,
    customer: customer ? { ID: customer?.ID, type: customer?.type } : undefined,
    contact: contactForBackend(contact),
    groupID: group?.ID,
    priorityID: parsePriorityID !== -1 ? parsePriorityID : undefined,
    links: parsedLinks,
    reminders: parsedReminders,
    shares: parsedShares,
    reportedAt,
    resolveUntil,
    respondUntil,
    scheduledFor,
    reportMethodID: reportMethod?.ID,
    tags,
    title,
    typeID: ticketType?.ID,
    stateID: state?.ID,
    data: formData,
    changeSourceInfo: {
      tabId: tabs.getState().getOwnTab().id,
    },
  };

  if (ID) {
    const patchTicketReturn = await patchTicket<T>(ID, submitData, params);

    return { ...patchTicketReturn, parentID, dependencies: subtickets };
  }
  throw Error('Tried to patch without ID');
};
