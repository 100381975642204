import { PropsWithChildren } from 'react';
import { Grid } from '@chakra-ui/react';

export const CalendarContainer = ({ children }: PropsWithChildren) => {
  return (
    <Grid
      flex="1 1 auto"
      w="full"
      gridTemplateColumns="1fr 20fr"
      m={0}
      h="full"
      overflow="hidden"
    >
      {children}
    </Grid>
  );
};
