import { ReactElement, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import {
  CalendarContainer,
  CalendarMain,
  CalendarProvider,
  CalendarSidebar,
  useCalendarController,
  useCalendarHandlers,
} from '@bq/components/Calendar';
import { PageWrapper } from 'BootQuery/Assets/components/PageWrapper';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

import { listCalendarEvents } from '../components/api';
import { CalendarTypeSelect } from '../components/Calendar/CalendarTypeSelect';
import { useEventTypes } from '../components/event-types';

export const Calendar = (): ReactElement => {
  const { t } = useTranslation('Events');
  useAppTitle(t('Events:calendar_title'));

  const defaultDate = useMemo(() => new Date(), []);

  const props = useCalendarController({
    startDate: defaultDate,
    startingView: 'month',
  });

  const { start, end } = props.dateRange;

  const {
    data: calendarEvents,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['Calendar', 'events', start.toISOString(), end.toISOString()],
    queryFn: () => {
      return listCalendarEvents({
        limit: 300,
        filters: {
          before: end.toISOString(),
          after: start.toISOString(),
        },
      });
    },
  });

  const eventTypes = useEventTypes();
  const { eventsToItems, itemClickHandler, dateClickHandler } =
    useCalendarHandlers(eventTypes);

  const items = useMemo(
    () => eventsToItems(calendarEvents ?? []),
    [calendarEvents, eventsToItems]
  );

  return (
    <PageWrapper p={0} m={0} h="fullNoNavbar">
      <CalendarProvider
        ID="Test-Ticketing-Calendar"
        isLoading={isLoading}
        {...props}
        items={items}
        calendarEventHandlers={{
          // OPTIONAL TODO: Maybe do query updates instead of refetching
          // You have complete info about single events inside
          itemAddHandler: () => {
            refetch();
          },
          itemUpdateHandler: () => {
            refetch();
          },
          itemClickHandler: (e) => {
            itemClickHandler(e);
          },
          dateCellClickHandler: (e) => {
            dateClickHandler(e);
          },
        }}
      >
        <CalendarContainer>
          <CalendarSidebar>
            <CalendarTypeSelect eventTypes={eventTypes} />
          </CalendarSidebar>
          <CalendarMain />
        </CalendarContainer>
      </CalendarProvider>
    </PageWrapper>
  );
};
