import { createContext, MutableRefObject, PropsWithChildren } from 'react';

import { makeUseContext } from '@bq/components/make-use-context';

interface ScrollRefCtxVal {
  scrollRef: MutableRefObject<HTMLDivElement | null>;
}
export const ScrollRefCtx = createContext<ScrollRefCtxVal | null>(null);

export const ScrollRefProvider = ({
  children,
  scrollRef,
}: PropsWithChildren<ScrollRefCtxVal>) => {
  return (
    <ScrollRefCtx.Provider value={{ scrollRef }}>
      {children}
    </ScrollRefCtx.Provider>
  );
};

export const useScrollRefCtx = makeUseContext({ ScrollRefCtx });
