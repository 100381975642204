import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

type CalendarTypesMap = Record<string, boolean>;

export const useCalendarFilters = () => {
  return useUserSetting<CalendarTypesMap>(
    'Calendar-TypeFilters',
    { ticket: false, appointment: false },
    false
  );
};
