import { omit } from 'lodash-es';

import { useCalendarCtx } from '../CalendarCtx';
import { caluculateClickHeight } from '../Utils/calculate-click-height';
import { useCalendarEvents } from './calendar-events';

export const useCalendarEventReducer = () => {
  const {
    calendarEventHandlers: {
      dateCellClickHandler,
      itemClickHandler,
      itemAddHandler,
      itemDeleteHandler,
      itemUpdateHandler,
    },
    ID,
    currentDate: [, setDate],
    view: [view, setView],
    disclosureState: { isOpen, onOpen },
  } = useCalendarCtx();

  useCalendarEvents('itemAdd', (event) => {
    if (itemAddHandler) {
      itemAddHandler(event);
    }
  });
  useCalendarEvents('itemDelete', (event) => {
    if (itemDeleteHandler) {
      itemDeleteHandler(event);
    }
  });
  useCalendarEvents('itemUpdate', (event) => {
    if (itemUpdateHandler) {
      itemUpdateHandler(event);
    }
  });
  useCalendarEvents('itemAdd', (event) => {
    if (itemAddHandler) {
      itemAddHandler(event);
    }
  });

  useCalendarEvents('itemClick', (event) => {
    if (ID === event.calendarID) {
      // This action is simple so we just call the external handler
      if (itemClickHandler) {
        itemClickHandler(event);
      }
    }
  });
  useCalendarEvents('dateCellClick', (event) => {
    if (ID === event.calendarID) {
      if (dateCellClickHandler) {
        const heightPercent = caluculateClickHeight(event.rawEvent);
        const withoutHeight = omit(event, ['rawEvent']);
        dateCellClickHandler({
          ...withoutHeight,
          topOffsetPercent: heightPercent,
        });
      }
    }
  });
  useCalendarEvents('showMoreClick', (event) => {
    if (ID === event.calendarID) {
      // Basically we try to expand it
      // If can't expand switch view so we can
      // fit more events
      // Or in day we should open some modal with rest or something
      if (!isOpen && view !== 'month') {
        onOpen();
      } else {
        if (view === 'month') {
          setView('week');
          setDate(event.targetDate);
        }
        if (view === 'week') {
          setView('day');
          setDate(event.targetDate);
        }
        /*
        if (view === 'day') {
          // optional TODO: open some modal with the rest
          // for the time being we agreed instead we should just show
          // all events
        } */
      }
    }
  });
  // optional TODO: register new events here
};
