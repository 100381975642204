import { ReactElement } from 'react';
import { ModalBody, ModalContent, ModalHeader } from '@chakra-ui/react';

import {
  DateCellClickEvent,
  useCalcRelativeStart,
} from '@bq/components/Calendar';
import { TypeSelectHeader } from '@bq/components/TicketModal/TypeSelect/TypeSelectHeader';

import { TicketingProvider } from '../../../TicketingContext';
import { TicketCalendarCreateModalBodyContent } from './TicketCalendarCreateModalBodyContent';

export const TicketCalendarCreateModalContent = (
  event: DateCellClickEvent
): ReactElement => {
  const calcRelativeStart = useCalcRelativeStart(event);

  return (
    <TicketingProvider>
      <ModalContent>
        <ModalHeader p={0}>
          <TypeSelectHeader />
        </ModalHeader>
        <ModalBody>
          <TicketCalendarCreateModalBodyContent
            relativeStart={calcRelativeStart}
          />
        </ModalBody>
      </ModalContent>
    </TicketingProvider>
  );
};
