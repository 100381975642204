import { ReactElement, useMemo } from 'react';
import { addMinutes } from 'date-fns';

import {
  DateCellClickEvent,
  useCalcRelativeStart,
} from '@bq/components/Calendar';

import {
  AppointmentEventsProvider,
  useAppointmentEvents,
} from '../Appointment/AppointmentEventsProvider';
import { AppointmentFormModal } from '../Appointment/AppointmentFormModal';

export const AppointmentCreateModalContent = (
  event: DateCellClickEvent
): ReactElement => {
  const handlers = useAppointmentEvents();

  const calcRelativeStart = useCalcRelativeStart(event);

  const calcRelativeEnd = useMemo(() => {
    return addMinutes(calcRelativeStart, 30);
  }, [calcRelativeStart]);

  return (
    <AppointmentEventsProvider {...handlers}>
      <AppointmentFormModal
        mode="create"
        defaultValues={{ startAt: calcRelativeStart, endAt: calcRelativeEnd }}
      />
    </AppointmentEventsProvider>
  );
};
