import { ReactNode } from 'react';

import { TranslationFunction } from 'BootQuery/Assets/js/globalTypes';

import { EventProviderMap } from '../types';

interface CalendarType {
  key: string;
  label: string | TranslationFunction;
  icon: ReactNode;
}
export const useCalendarTypes = (eventProviderMap: EventProviderMap) => {
  return Object.entries(eventProviderMap).reduce<CalendarType[]>(
    (all, [key, provider]) => {
      if (provider.calendar) {
        return [
          ...all,
          {
            key,
            label: provider.calendar.info.name,
            icon: provider.calendar.info.icon,
          },
        ];
      }

      return all;
    },
    []
  );
};
