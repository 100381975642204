import { useCallback, useState } from 'react';
import { Input } from '@chakra-ui/react';
import { useUpdateEffect } from 'react-use';

import { useAutosizingInput } from '@bq/components/use-autosizing-input';
import { parseToNumber } from 'BootQuery/Assets/js/utils';

import { NumberRange, NumberRangeInputProps } from '../types';

export const NumberTagRangeInput = ({
  isNew,
  onChange,
  value,
}: NumberRangeInputProps) => {
  const [internalValue, setInternalValue] = useState<{
    from: number | undefined;
    to: number | undefined;
  }>(value ?? { from: 0, to: 0 });
  const handleChange = useCallback((value: string, target: 'from' | 'to') => {
    const parseValueToNumber = parseToNumber(value);
    setInternalValue((prev) => ({ ...prev, [target]: parseValueToNumber }));
  }, []);
  useUpdateEffect(() => {
    if (onChange) {
      onChange({ to: internalValue.to ?? 0, from: internalValue.from ?? 0 });
    }
  }, [internalValue, onChange]);
  const inputEl1 = useAutosizingInput<number | NumberRange>(
    internalValue.from || 100
  );
  const inputEl2 = useAutosizingInput<number | NumberRange>(
    internalValue.to || 100
  );

  return (
    <>
      <Input
        type="number"
        value={internalValue.from}
        onChange={(ev) => {
          handleChange(ev.target.value, 'from');
        }}
        ref={inputEl1}
        minWidth="4"
        border="none"
        background="none"
        borderLeftRadius={0}
        size="sm"
        borderLeft="2px solid"
        borderRight="2px solid"
        borderColor="gray.400"
        autoFocus={isNew}
        _dark={{
          borderColor: 'gray.600',
        }}
      />
      <Input
        type="number"
        value={internalValue.to}
        onChange={(ev) => {
          handleChange(ev.target.value, 'to');
        }}
        ref={inputEl2}
        minWidth="4"
        border="none"
        background="none"
        borderLeftRadius={0}
        size="sm"
        borderRight="2px solid"
        borderColor="gray.400"
        _dark={{
          borderColor: 'gray.600',
        }}
      />
    </>
  );
};
