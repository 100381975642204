import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

import { ColorPicker } from 'BootQuery/Assets/components/ColorPicker';
import { FixedPopoverContent } from 'BootQuery/Assets/components/FixedPopoverContent';

import { Portal } from '../Portal';
import { ColorButton } from './ColorButton';

interface BaseProps {
  clearable?: boolean;
  value?: string | null;
  size?: string;
}
interface ClearableProps extends BaseProps {
  onChange?: (color: string | null) => void;
  clearable: true;
}
interface NonClearableProps extends BaseProps {
  onChange?: (color: string) => void;
  clearable?: false;
}
type Props = ClearableProps | NonClearableProps;

export const ColorInput = ({ value, size, ...props }: Props): ReactElement => {
  const { t } = useTranslation();
  const [color, setColor] = useState<string | null>(value ?? null);

  // Sync outside changes
  useEffect(() => {
    setColor(value ?? null);
  }, [value]);

  const handleChange = useCallback(
    (newVal: string | null) => {
      setColor(newVal);
      if (props.onChange) {
        if (props.clearable) {
          props.onChange(newVal);
        } else if (newVal) {
          props.onChange(newVal);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange, props.clearable]
  );

  const clearColor = useCallback(() => {
    handleChange(null);
  }, [handleChange]);

  return (
    <Popover>
      <PopoverTrigger>
        <ColorButton
          w={size}
          h={size}
          _hover={{
            transform: 'scale(1.0)',
            backgroundColor: color ?? 'white',
          }}
          backgroundColor={color ?? 'white'}
          borderColor="gray.500"
        >
          {!color && <FaTimes />}
        </ColorButton>
      </PopoverTrigger>
      <Portal>
        <FixedPopoverContent width="auto" p={0} isLazy>
          <PopoverArrow />
          <PopoverBody p={0}>
            <ColorPicker
              border="none"
              value={color ?? 'white'}
              onChange={handleChange}
              borderBottomRadius={props.clearable ? 0 : undefined}
            />
            {props.clearable && (
              <Button size="sm" display="flex" w="full" onClick={clearColor}>
                <FaTimes />
                {t('global:clear')}
              </Button>
            )}
          </PopoverBody>
        </FixedPopoverContent>
      </Portal>
    </Popover>
  );
};
