import { MouseEvent } from 'react';
import mitt from 'mitt';

import { PartialExceptFor } from '@bq/components/type-util';
import { OBJ } from 'BootQuery/Assets/js/globalTypes';
import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

import {
  CalendarItem,
  DateCellClickEvent,
  DeleteCalendarItem,
  ItemClickEvent,
} from '../types';

interface RawDateCellClickEvent
  extends Omit<DateCellClickEvent, 'topOffsetPercent'> {
  rawEvent: MouseEvent<HTMLDivElement>;
}
interface ShowMoreClickEvent {
  calendarID: string;
  targetDate: Date;
}
export type CalendarEvents = {
  dateCellClick: RawDateCellClickEvent;
  itemClick: ItemClickEvent;
  showMoreClick: ShowMoreClickEvent;
  itemAdd: CalendarItem;
  itemUpdate: PartialExceptFor<CalendarItem, 'ID'>;
  itemDelete: DeleteCalendarItem;
  // optional TODO: Register future events here
  // like item add, delete, edit
};

export const calendarEvents = mitt<CalendarEvents>();

export const useCalendarEvents = createUseMitt(calendarEvents);

export const handleDateCellClick = (event: RawDateCellClickEvent) => {
  calendarEvents.emit('dateCellClick', event);
};

export const handleItemClick = (event: ItemClickEvent) => {
  calendarEvents.emit('itemClick', event);
};

export const handleShowMoreClick = (event: ShowMoreClickEvent) => {
  calendarEvents.emit('showMoreClick', event);
};

export const handleCalendarItemAdd = <
  AdditionalProps extends OBJ = OBJ,
  T extends string = string,
>(
  event: CalendarItem<AdditionalProps, T>
) => {
  calendarEvents.emit('itemAdd', event);
};

export const handleCalendarItemUpdate = <
  AdditionalProps extends OBJ = OBJ,
  T extends string = string,
>(
  event: PartialExceptFor<CalendarItem<AdditionalProps, T>, 'ID'>
) => {
  calendarEvents.emit('itemUpdate', event);
};

export const handleCalendarItemDelete = (event: DeleteCalendarItem) => {
  calendarEvents.emit('itemDelete', event);
};
