import { omit } from 'lodash-es';

import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { getPeople, PeopleResponse } from '../api';
import { ApiNumber, ListItem, PersonApiData } from '../types';

interface PersonWithUser extends PersonApiData {
  user: { pbxUser: { phoneNumber: { phoneNumberE164: string } } | null } | null;
}

type RawResponse = ListingResponse<PersonWithUser>;

const extenType: ListItem = { ID: 9, name: 'Interni' };
export function allPersonNumbers(person: PersonWithUser): ApiNumber[] {
  const exten = person.user?.pbxUser?.phoneNumber ?? null;
  const extenInList = person.phoneNumbers.some(
    (num) => num.phoneNumber.phoneNumberE164 === exten?.phoneNumberE164
  );

  if (exten && !extenInList) {
    return [
      { numberType: extenType, phoneNumber: exten },
      ...person.phoneNumbers,
    ];
  }

  return person.phoneNumbers;
}

export async function getCardPeople(
  params: GetListingParams
): Promise<PeopleResponse> {
  const resp = await getPeople<RawResponse>({
    fields: [
      'ID',
      'firstName',
      'lastName',
      ['company', ['ID', 'name']],
      ['department', ['ID', 'name']],
      ['companyPosition', ['ID', 'name']],
      ['companyLocation', ['ID', 'name']],
      [
        'addresses',
        [
          ['addressType', ['name', 'ID']],
          [
            'address',
            [
              'country.name',
              'city.name',
              'street.name',
              'postalCode.code',
              'streetNumber',
            ],
          ],
        ],
      ],
      ['nationalNumber', [['type', ['type', 'countryISO']], 'nationalNumber']],
      ['phoneNumbers', ['phoneNumber.phoneNumberE164', 'numberType.ID']],
      'user.pbxUser.phoneNumber.phoneNumberE164',
      'emails.email.email',
      'data',
    ],
    ...params,
  });

  return {
    ...resp,
    data: resp.data.map((person) => ({
      ...omit(person, 'user'),
      phoneNumbers: allPersonNumbers(person),
    })),
  };
}
