import { ChangeEvent, ReactElement, SetStateAction, useCallback } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ColorInput } from '@bq/components/ColorInput';

import {
  ContactItem,
  ContactItemContact,
} from '../../HopsOperator/HopsContacts/types';
import { ContactNumberSelect } from './ContactNumberSelect';

export interface SelectedContact {
  idx: number;
  contact: ContactItem | null;
}

interface Props {
  contactToEdit: SelectedContact | null;
  onClose: () => void;
  setContact: (
    idx: number,
    changed: SetStateAction<ContactItem | null>
  ) => void;
}

export const EditContact = ({
  contactToEdit,
  setContact,
  onClose,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const editIdx = contactToEdit?.idx ?? null;
  const handleContactChange = useCallback(
    (value: ContactItemContact | null) => {
      if (editIdx === null) {
        throw new Error('Tried to make changes without selected contact');
      }

      console.log('Set contact: ', value, editIdx);
      setContact(editIdx, (prev) => ({
        ...prev,
        contact: value,
      }));
    },
    [editIdx, setContact]
  );
  const handleColorChange = useCallback(
    (color: string | null) => {
      if (editIdx === null) {
        throw new Error('Tried to make changes without selected contact');
      }

      setContact(editIdx, (prev) => ({
        ...prev,
        contact: prev?.contact ?? null,
        color,
      }));
    },
    [editIdx, setContact]
  );
  const handleSuffixChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      if (editIdx === null) {
        throw new Error('Tried to make changes without selected contact');
      }

      setContact(editIdx, (prev) => ({
        ...prev,
        contact: prev?.contact ?? null,
        suffix: ev.target.value,
      }));
    },
    [editIdx, setContact]
  );
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal isOpen={contactToEdit !== null} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader />
        <ModalCloseButton />
        <ModalBody>
          <VStack width="full" alignItems="stretch" spacing="4">
            <HStack>
              <FormControl flex="0 1 auto">
                <FormLabel fontWeight="bold">{t('global:color')}:</FormLabel>
                <ColorInput
                  value={contactToEdit?.contact?.color ?? null}
                  onChange={handleColorChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="bold">
                  {t('Telephony:operator.suffix')}:
                </FormLabel>
                <Input
                  value={contactToEdit?.contact?.suffix ?? ''}
                  onChange={handleSuffixChange}
                />
              </FormControl>
            </HStack>
            <FormControl>
              <FormLabel fontWeight="bold">
                {t('Telephony:operator.contact')}:
              </FormLabel>
              {contactToEdit && (
                <ContactNumberSelect
                  value={contactToEdit.contact?.contact ?? null}
                  onChange={handleContactChange}
                />
              )}
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" variant="link" onClick={handleClose}>
            {t('global:close')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
