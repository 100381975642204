import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Button, Flex, Grid, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { ContactItem, Contacts } from '../../HopsOperator/HopsContacts/types';
import {
  CONTACTS_PER_PAGE,
  getFixedSizeContacts,
  getTextColor,
} from '../../HopsOperator/HopsContacts/util';
import { EditContact, SelectedContact } from './EditContact';

interface Props {
  categoryId: string;
  value: Contacts;
  onChange: Dispatch<SetStateAction<Contacts>>;
}

export const EditContacts = ({
  categoryId,
  value,
  onChange,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const [page, setPage] = useState(1);
  const [editIdx, setEditIdx] = useState<number | null>(null);
  const setContact = useCallback(
    (idx: number, changed: SetStateAction<ContactItem | null>) => {
      onChange((prev) => {
        if (prev.length < idx + 1) {
          prev = getFixedSizeContacts(prev, idx + 1);
        }

        return prev.map((prevContact, prevIdx) => {
          if (prevIdx !== idx) {
            return prevContact;
          }

          return typeof changed === 'function'
            ? changed(prevContact)
            : prevContact;
        });
      });
    },
    [onChange]
  );
  const contactToEdit: SelectedContact | null = useMemo(() => {
    if (editIdx === null) {
      return null;
    }

    return {
      idx: editIdx,
      contact: value[editIdx] ?? null,
    };
  }, [editIdx, value]);
  const handleEditClose = useCallback(() => {
    setEditIdx(null);
  }, []);

  const prevPage = useCallback(() => {
    setPage((prev) => Math.max(prev - 1, 1));
  }, []);
  const nextPage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  useChangeEffect(categoryId, () => {
    setPage(1);
  });

  const visibleItems = useMemo(() => {
    const offset = (page - 1) * CONTACTS_PER_PAGE;
    const pageContacts = value.slice(offset, offset + CONTACTS_PER_PAGE);

    // Run it through this to return empty values in case page is missing
    return getFixedSizeContacts(pageContacts);
  }, [page, value]);

  return (
    <VStack w="full" h="full" alignItems="stretch">
      <Flex justifyContent="space-between" mb="3">
        <IconButton
          label={t('global:pagination.previous_page')}
          icon={<FaChevronLeft />}
          isDisabled={page === 1}
          onClick={prevPage}
          size="sm"
        />
        {page}
        {' / '}
        {value.length / CONTACTS_PER_PAGE}
        <IconButton
          label={t('global:pagination.next_page')}
          icon={<FaChevronRight />}
          onClick={nextPage}
          size="sm"
        />
      </Flex>

      <Grid
        height="full"
        templateColumns="repeat(4, 1fr)"
        gridAutoRows="1fr"
        gap="2"
      >
        {visibleItems.map((val, rawIdx) => {
          const idxOffset = CONTACTS_PER_PAGE * (page - 1);
          const idx = idxOffset + rawIdx;
          const edit = () => {
            setEditIdx(idx);
          };

          if (!val || !val.contact) {
            return (
              <Button
                key={`${categoryId}-${idx}`}
                variant="unstyled"
                height="auto"
                borderStyle="dotted"
                borderWidth="medium"
                borderColor="gray.400"
                onClick={edit}
                _hover={{
                  borderColor: 'gray.500',
                }}
              />
            );
          }

          const ensuredColor = val.color ?? '#edf2f7';
          const textColor = getTextColor(ensuredColor);

          return (
            <Button
              key={`${categoryId}-${idx}`}
              background={ensuredColor}
              color={textColor}
              variant="unstyled"
              px="3"
              py="2"
              height="auto"
              overflow="hidden"
              textOverflow="ellipsis"
              onClick={edit}
            >
              {val.contact.name} {val.suffix}
            </Button>
          );
        })}
      </Grid>
      <EditContact
        contactToEdit={contactToEdit}
        setContact={setContact}
        onClose={handleEditClose}
      />
    </VStack>
  );
};
