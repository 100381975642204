import { ChangeEvent, ReactElement, SetStateAction, useCallback } from 'react';
import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

import { ColorInput } from '@bq/components/ColorInput';
import { DeleteButton } from '@bq/components/DeleteButton';
import { IconButton } from '@bq/components/IconButton';

import {
  ContactCategory,
  Contacts,
  UserGroup,
} from '../../HopsOperator/HopsContacts/types';
import { getFixedSizeContacts } from '../../HopsOperator/HopsContacts/util';
import { GenerateCardsButton } from '../GenerateCards';
import { CategoryGroups } from './CategoryGroups';
import { EditContacts } from './EditContacts';

interface Props {
  groups: UserGroup[];
  value: ContactCategory | null;
  onChange: (id: string, newVal: SetStateAction<ContactCategory>) => void;
  removeCategory: (categoryId: string) => void;
  moveCategory: (categoryId: string, direction: 'up' | 'down') => void;
  isFirst?: boolean;
  isLast?: boolean;
}

export const CategoryEdit = ({
  value,
  onChange,
  removeCategory,
  moveCategory,
  groups,
  isFirst,
  isLast,
}: Props): ReactElement => {
  if (!value) {
    return <></>;
  }

  return (
    <CategoryEditContent
      value={value}
      onChange={onChange}
      groups={groups}
      removeCategory={removeCategory}
      moveCategory={moveCategory}
      isFirst={isFirst}
      isLast={isLast}
    />
  );
};

interface ContentProps extends Omit<Props, 'value'> {
  value: ContactCategory;
}

const CategoryEditContent = ({
  value,
  onChange,
  removeCategory,
  moveCategory,
  isFirst,
  isLast,
  groups,
}: ContentProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  const handleNameChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      onChange(value.id, (prev) => ({ ...prev, name: ev.target.value }));
    },
    [value.id, onChange]
  );
  const handleColorChange = useCallback(
    (color: string | null | undefined) => {
      onChange(value.id, (prev) => ({ ...prev, color: color ?? null }));
    },
    [value.id, onChange]
  );
  const handleContactsChange = useCallback(
    (change: SetStateAction<Contacts>) => {
      onChange(value.id, (prev) => {
        const contacts =
          typeof change === 'function' ? change(prev.contacts) : change;

        return {
          ...prev,
          contacts,
        };
      });
    },
    [value.id, onChange]
  );
  const handleGroupsChange = useCallback(
    (change: SetStateAction<number[]>) => {
      onChange(value.id, (prev) => {
        const groups =
          typeof change === 'function' ? change(prev.groups) : change;

        return {
          ...prev,
          groups,
        };
      });
    },
    [value.id, onChange]
  );

  const handleContactGenerate = useCallback(
    (contacts: Contacts) => {
      handleContactsChange(getFixedSizeContacts(contacts));
    },
    [handleContactsChange]
  );

  const moveUp = useCallback(() => {
    moveCategory(value.id, 'up');
  }, [value.id, moveCategory]);
  const moveDown = useCallback(() => {
    moveCategory(value.id, 'down');
  }, [value.id, moveCategory]);
  const deleteSelf = useCallback(() => {
    removeCategory(value.id);
  }, [value.id, removeCategory]);

  return (
    <Flex direction="column" height="full">
      <Box
        display="grid"
        gridTemplateColumns="1fr 6fr"
        rowGap="2"
        flex="0 1 auto"
        px="3"
      >
        <FormControl display="contents">
          <FormLabel fontWeight="bold">{t('global:name')}:</FormLabel>
          <Input type="text" value={value.name} onChange={handleNameChange} />
        </FormControl>
        <FormControl display="contents">
          <FormLabel fontWeight="bold">{t('global:color')}:</FormLabel>
          <ColorInput
            clearable
            value={value.color}
            onChange={handleColorChange}
          />
        </FormControl>
      </Box>
      <Flex px="3" flex="0 1 auto" justifyContent="space-between">
        <ButtonGroup isAttached>
          <IconButton
            size="sm"
            icon={<FaArrowUp />}
            label={t('global:move_up')}
            onClick={moveUp}
            isDisabled={isFirst}
          />
          <IconButton
            size="sm"
            icon={<FaArrowDown />}
            label={t('global:move_down')}
            onClick={moveDown}
            isDisabled={isLast}
          />
        </ButtonGroup>
        <GenerateCardsButton
          setCards={handleContactGenerate}
          defaultColor={value.color}
        />
        <DeleteButton
          size="sm"
          label={t('global:delete')}
          onClick={deleteSelf}
        />
      </Flex>
      <Divider />
      <CategoryGroups
        value={value.groups}
        onChange={handleGroupsChange}
        groups={groups}
      />
      <Divider />
      <Box flex="1 1 auto" px="2" py="1">
        <EditContacts
          categoryId={value.id}
          value={value.contacts}
          onChange={handleContactsChange}
        />
      </Box>
    </Flex>
  );
};
