import { ReactElement } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const UnknownParty = (props: TextProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  return (
    <Text as="span" color="red.500" {...props}>
      {t('Telephony:details.unknown')}
    </Text>
  );
};
