import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import {
  getReadableSoftFontColor,
  pastelizeColor,
} from '@bq/components/ColorUtils';

import { useCalendarCtx } from '../../CalendarCtx';
import { handleItemClick } from '../../Events/calendar-events';
import { CalendarItemWithAdditionalProps } from './utils/types';
import { useGetClipPath } from './utils/use-get-clip-path';

export const MonthViewItem = (
  props: CalendarItemWithAdditionalProps & { idx: number }
) => {
  const {
    column,
    order,
    row,
    title,
    color,
    icon,
    idx,
    isWholeDayEvent,
    startDate,
    overflowsLeft,
    overflowsRight,
    ID,
    endDate,
    type,
    additionalProps,
  } = props;
  const bg = pastelizeColor(color ?? '#515152');
  const fontColor = getReadableSoftFontColor(bg);
  const {
    ID: calendarID,
    view: [view],
  } = useCalendarCtx();

  const startTime = useMemo(() => {
    return isWholeDayEvent ? null : format(startDate, 'HH:mm');
  }, [isWholeDayEvent, startDate]);

  const clipPath = useGetClipPath(overflowsLeft, overflowsRight);

  return (
    <HStack
      onClick={() => {
        handleItemClick({
          calendarID,
          view,
          item: {
            ID,
            endDate,
            isWholeDayEvent,
            startDate,
            title,
            type,
            additionalProps,
            color,
            icon,
          },
        });
      }}
      clipPath={clipPath}
      cursor="pointer"
      rounded="md"
      px={2}
      w="full"
      gridColumn={`${column.start} / ${column.end}`}
      gridRow={`${row.start} / ${row.end}`}
      pos="relative"
      fontSize="sm"
      color={fontColor}
      top={idx === 0 ? `${order * 20}px` : `${order * 21}px`}
      h="20px"
      bg={bg}
    >
      {icon && <Box>{icon}</Box>}
      <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
        {isWholeDayEvent ? title : `${startTime}-${title}`}
      </Box>
    </HStack>
  );
};
