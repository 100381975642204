import { ReactElement, useCallback, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaList } from 'react-icons/fa';

import { useFilterTypes } from '@bq/components/ListingSettings/utils';
import {
  IOverviewEditorItem,
  OverviewEditorModal,
} from '@bq/components/Overviews';
import { useDOMInputJSON } from '@bq/components/use-dom-input';

import { getOverviewSettings } from '../Api/overview-settings';
import { ticketFilterTypes } from '../filter-types';
import { useTicketingSettingsContext } from './TicketSettingsContext';

export const EditOverviews = (): ReactElement => {
  const { customFields } = useTicketingSettingsContext();

  const { t } = useTranslation('Ticketing');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [stored, setStored] = useDOMInputJSON<IOverviewEditorItem[] | null>(
    '#settings-form',
    'ticketing[overviews]',
    null
  );

  const filters = useFilterTypes(ticketFilterTypes, customFields);

  const overviewGetter = useCallback(
    () => getOverviewSettings(filters),
    [filters]
  );

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onChange = useCallback(
    (value: IOverviewEditorItem[]) => {
      setStored(value);
    },
    [setStored]
  );

  return (
    <>
      <Button type="button" onClick={open} leftIcon={<FaList />}>
        {t('global:edit_overviews')}
      </Button>
      <OverviewEditorModal
        queryKey="Ticketing"
        value={stored ?? overviewGetter}
        isOpen={isOpen}
        onClose={close}
        onChange={onChange}
        filterTypes={filters}
        withGroupVisibility
      />
    </>
  );
};
