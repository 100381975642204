import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { ModalBody, ModalFooter } from '@chakra-ui/react';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { FilterTypes } from '../../FilterBar/types';
import { FormActions } from '../../FormActions';
import { lazyImport } from '../../lazy-import';
import { IOverviewEditorItem, OverviewGetter } from '../types';

const OverviewEditor = lazyImport(
  () => import('./OverviewEditor'),
  'OverviewEditor'
);

export interface OverviewEditorModalContentProps {
  isOpen: boolean;
  onClose: () => void;
  filterTypes: FilterTypes;
  value: IOverviewEditorItem[] | OverviewGetter;
  onChange: (value: IOverviewEditorItem[]) => void;
  withGroupVisibility?: boolean;
  queryKey: string;
}

export interface EditorContentHandle {
  cancel: () => void;
  submit: () => void;
}

const EditorContentComponent: ForwardRefRenderFunction<
  EditorContentHandle,
  OverviewEditorModalContentProps
> = (
  {
    onClose,
    filterTypes,
    value: inValue,
    onChange,
    withGroupVisibility,
    queryKey,
  },
  ref
) => {
  const queryClient = useQueryClient();
  const getOverviews = useCallback(async () => {
    if (typeof inValue === 'function') {
      return inValue();
    }

    return inValue;
  }, [inValue]);
  const { data: initialOverviews } = useSuspenseQuery({
    queryKey: ['overviewEditor', getOverviews, queryKey],
    queryFn: getOverviews,
  });
  const overviewsValue = useRef(initialOverviews);

  const cancel = () => {
    onClose();
    overviewsValue.current = initialOverviews;
  };
  const submit = () => {
    onChange(overviewsValue.current); // Submit changes on confirm
    queryClient.resetQueries({ queryKey: ['overviewEditor', getOverviews] });
    onClose();
  };

  useImperativeHandle(ref, () => ({ submit, cancel }));

  return (
    <>
      <ModalBody
        px={0}
        maxH="fullModalHeight"
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <OverviewEditor
          value={initialOverviews}
          onChange={(state) => {
            overviewsValue.current = state;
          }}
          filterTypes={filterTypes}
          withGroupVisibility={withGroupVisibility}
        />
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <FormActions onCancel={cancel} onSubmit={submit} />
      </ModalFooter>
    </>
  );
};
const OverviewEditorModalContent = forwardRef(EditorContentComponent);
OverviewEditorModalContent.displayName = 'OverviewEditorModalContent';

export { OverviewEditorModalContent };
