import { ReactElement, ReactNode, useContext } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { hr } from 'date-fns/locale/hr';
import { FaEnvelope } from 'react-icons/fa';

import { useEventList } from 'app/Modules/Events/Assets/components/EventList/EventListContext';
import { Card, CardFooter, CardHeader } from 'BootQuery/Assets/components/Card';

import { Message as IMessage } from '../../js/types';
import { Addresses } from './Addresses';
import { AttachmentList } from './AttachmentList';
import { MessageActions } from './MessageActions';
import { MessageActionsContext } from './MessageActionsContext';
import { MessageBody } from './MessageBody';
import { MessageMenu } from './MessageMenu';
import { useGroupedAddresses } from './util';

export const formatDate = (date: Date | string | number | null): string => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return format(dateObj, 'Pp', { locale: hr });
};

export interface MessageProps extends IMessage {
  rightMenuAddons?: ReactNode;
}

export const Message = (message: MessageProps): ReactElement => {
  const {
    ID,
    subject,
    date,
    fromMailAddress,
    addresses,
    textBody,
    htmlBody,
    attachments,
    rightMenuAddons,
  } = message;

  const { to, cc, bcc } = useGroupedAddresses(addresses);
  const actions = useContext(MessageActionsContext);
  const { viewOnly } = useEventList();

  return (
    <Card shadow="base" w="full">
      <CardHeader py="2" px="3">
        <HStack mb="3">
          <Text flex="1 1 auto" fontWeight="bold" mb={0}>
            <FaEnvelope style={{ display: 'inline' }} />
            &nbsp;
            {subject}
          </Text>
          <Text as="span" color="gray.500">
            {formatDate(date)}
          </Text>
          <MessageMenu message={message} itemsAfter={rightMenuAddons} />
        </HStack>
        <Addresses
          label="From"
          addresses={[{ email: fromMailAddress, contact: message.fromContact }]}
        />
        <Addresses label="To" addresses={to} />
        {cc.length > 0 && <Addresses label="CC" addresses={cc} />}
        {bcc.length > 0 && <Addresses label="BCC" addresses={bcc} />}
      </CardHeader>

      <MessageBody textBody={textBody} htmlBody={htmlBody} />

      {(attachments.length > 0 || !!actions) && (
        <CardFooter py="2" px="3">
          {attachments.length > 0 && (
            <AttachmentList messageID={ID} attachments={attachments} />
          )}
          {actions && !viewOnly && (
            <MessageActions {...actions} message={message} />
          )}
        </CardFooter>
      )}
    </Card>
  );
};
